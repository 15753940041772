import React from 'react';
import './cta.css';

const cta = () => {
  return (
    <div className='gpt3__cta' id="cta">
      <div className='gpt3__cta-content'>
        <p>Schedule a Laundry Pickup Today</p>
        <h3>Schedule a pickup today & have clean laundry by tomorrow!</h3>
        
      </div>
      <div className='gpt3__cta-btn'>
        <button type="button">Schedule Pickup</button>
      </div>
    </div>
  )
}

export default cta