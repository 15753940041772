import React from 'react';
import'./header.css';

const Header = () => {
  return (
    <div className='bubbles3__header section__padding' id="home">
        <div className='bubbles3__header-content'>
            <div class="bubbles3__header-content__input content">
					
				<div class="bubbles3__circle bubbles3__circle-container"></div>
				<div class="bubbles3__circle"></div>
				<div class="bubbles3__circle bubbles3__circle-container"></div>
				<div class="bubbles3__circle"></div>
				<div class="bubbles3__content-details">
					<h1 class="bubbles3__title">Norfolk Laundry</h1>
					<p class="bubbles3__description">
						A Family-Owned Business!
					</p>
					<button class="bubbles3__btn" id="btn">
						Schedule Pickup!
					</button>
				</div>
			</div>
        </div>
    </div>
  )
}

export default Header