import React, { useState }  from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';

const Menu =() => (
    <>
      <p><a href="#home">Home</a></p>
      <p><a href="#services">Services</a></p>
      <p><a href="#contact">Contact</a></p>
      <p><a href="#cta">CTA</a></p>
      <p><a href="#reviews">Reviews</a></p>
    </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='bubbles3__navbar'>
        <div className='bubbles3__navbar-links'>
            <div className='bubbles3__navbar-links_logo' >
                <h2>Norfolk Laundry</h2>
            </div>
            
        </div> 
        <div className='bubbles3__navbar-links_container'>
                <Menu />
            </div>
        <div className='bubbles3__navbar-menu'>
            {toggleMenu
            ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
            }
            {toggleMenu && (
                <div className='bubbles3__navbar-menu_container scale-up-center'>
                    <div className='bubbles3__navbar-menu_container-links'>
                        <Menu />
                    </div>
                </div>
            )}
        </div> 
    </div>
  )
}

export default Navbar