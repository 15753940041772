import React from 'react'
import './reviews.css';
import { review1, review2 } from './imports.js';

const reviews = () => {
  return (
    <div className='bubbles3__reviews' id="reviews">
      {/* Slideshow container */}
      <div class="slideshow-container">
        <div class="slide-track">
          {/* Full-width images */}
          <div class="mySlides services-item">
            <img src={review1} alt='review1' class="services-img"/>
          </div>
          <div class="mySlides services-item">
            <img src={review2} alt='review2' class="services-img"/>
          </div>
          <div class="mySlides services-item">
            <img src={review1} alt='review1' class="services-img"/>
          </div>
          <div class="mySlides services-item">
            <img src={review2} alt='review2' class="services-img"/>
          </div>
          <div class="mySlides services-item">
            <img src={review1} alt='review1' class="services-img"/>
          </div>
          <div class="mySlides services-item">
            <img src={review2} alt='review2' class="services-img"/>
          </div>
          <div class="mySlides services-item">
            <img src={review1} alt='review1' class="services-img"/>
          </div>
          <div class="mySlides services-item">
            <img src={review2} alt='review2' class="services-img"/>
          </div>
        </div>
        <br />
      
    </div></div>
  )
}

export default reviews