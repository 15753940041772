import React from 'react';

import { Footer, Header } from './containers';
import { CTA, Navbar, Reviews } from './components';
import './App.css';

const App = () => {
  return (
    <div className='App'>
        <div className='gradient__bg'>
        
          <Navbar />
          <Header />

        </div>
        <CTA />
        <Reviews />
        <Footer />
    </div>
  )
}

export default App