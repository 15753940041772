import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className='bubbles3__footer section__padding'>
      <div className='bubbles3__footer-links'>
        <div className='bubbles3__footer-links_logo'>
          <div className='bubbles3__footer-links_logo' >
            <h2>Norfolk Laundry</h2>
          </div>
          <p>Crechterwoord K12 182 DK Alknjkcb, <br /> All Rights Reserved</p>
        </div>
        <div className='bubbles3__footer-links_div'>
          <h4>Links</h4>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p>
        </div>
        <div className='bubbles3__footer-links_div'>
          <h4>company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className='bubbles3__footer-links_div'>
          <h4>Get in touch</h4>
          <p>Crechterwood K12 182 DK Alknjkcb</p>
          <p>885-132567</p>
          <p>info@payme.net</p>
        </div>
      </div> 
      <div className='bubbles3__footer-copyright'>
        <p>© 2021 Bubbles.  All rights reserved.</p>
      </div>   
    </div>
  )
}

export default Footer